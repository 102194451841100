<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\servertasks\\list.2090') }}</v-toolbar-title>
                <v-spacer />
                <v-btn v-if="user.adminAccess && user.adminAccess.server_tasks >= ACCESS.WRITE" color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon>{{ $t('src\\views\\servertasks\\list.3208') }}</v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
            {{ $t(SERVER_TASK_TYPE_TITLE[item.type]) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
            {{ $t(SERVER_TASK_STATUS_TITLE[item.status]) }}
            <v-menu v-if="user.adminAccess && user.adminAccess.server_tasks >= ACCESS.WRITE">
                <template v-slot:activator="{ attrs, on }">
                    <v-icon
                        color="primary"
                        small
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                    >mdi-pencil</v-icon>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(status, index) in SERVER_TASK_STATUS_TITLE"
                        :key="index"
                        @click="setStatus(item._id, index)"
                    >
                        <v-list-item-title>{{ $t(status) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="primary" @click="edit(item._id)">mdi-pencil</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { SERVER_TASK_TYPE_TITLE, SERVER_TASK_STATUS_TITLE } from '@/vars';
    import { ACCESS } from '@/vars';

    export default {
        name: 'ServerTasksList',
        components: {
        },
        data: () => ({
            ACCESS,
            SERVER_TASK_TYPE_TITLE,
            SERVER_TASK_STATUS_TITLE,
            headers: [],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('serverTasks', ['entities']),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            await store.dispatch('serverTasks/fetch', {});
            this.headers = [
                { text: this.$t('src\\views\\servertasks\\list.createdAt'), value: 'createdAt' },
                { text: this.$t('src\\views\\servertasks\\list.type'), value: 'type' },
                { text: this.$t('src\\views\\servertasks\\list.status'), value: 'status' },
                { text: this.$t('src\\views\\servertasks\\list.name'), value: 'name' },
                { text: this.$t('src\\views\\servertasks\\list.description'), value: 'description' },
            ];
            if(this.user && this.user.adminAccess && this.user.adminAccess.server_tasks >= ACCESS.WRITE) {
                this.headers.push({ text: '', align: 'right', value: 'edit' })
            }
        },
        methods: {
            async create() {
                await this.$router.push({ name: 'server-tasks-create' });
            },
            async edit(id) {
                await this.$router.push({ name: 'server-tasks-edit', params: { id } });
            },
            async remove(id) {
                await store.dispatch('serverTasks/delete', { id });
                await store.dispatch('serverTasks/fetch', {});
            },
            async setStatus(id, status) {
                await store.dispatch('serverTasks/setStatus', { id, status });
                await store.dispatch('serverTasks/fetch', {});
            }
        }
    }
</script>
